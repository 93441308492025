import React from "react";
import "./Services.css";

const Services = () => {
  return (
    <div id="services">
      <div className="service">
        <div className="title">
          <h2 className="project-heading">
            Projects <br />
            <p className="smallspan">
              Let's see
              <span className="bigspan bigspan2">
                <b>What I Build</b>
              </span>
            </p>
          </h2>
        </div>

        <div className="box">
          <div className="card">
            <h5>Project 1</h5>
            <div className="pra">
              <h6>Coding Quest</h6>
              <p>
                This groundbreaking project is your ultimate destination to
                discover and dominate the hottest upcoming coding contests.
                <p>Did you like it??</p>
              </p>

              <p className="seconddiv">
                <a
                  className="button"
                  href="https://anicrate.github.io/Coding-Quest/"
                >
                  Try it Out
                </a>
              </p>
            </div>
          </div>

          <div className="card">
            <h5>Project 2</h5>
            <div className="pra">
              <h6>Complaint Forum</h6>
              <p>
                Tired of waiting in queues? Don't worry, got a complaint forum
                where not only you can avoid the long queues but can track your
                complaints and get solutions in a haslte free way.
                <p>Did you like it??</p>
              </p>

              <p className="seconddiv">
                <a
                  className="button"
                  href="https://anicrate.github.io/Hack-nitr/"
                >
                  Check Out
                </a>
              </p>
            </div>
          </div>

          <div className="card">
            <h5>Project 3</h5>
            <div className="pra">
              <h6>CropFinder</h6>
              <p>
                🌾 A hackathon project that provides insights into crops grown
                in different states and union territories, offering valuable
                agricultural information.
                <p>Did you like it??</p>
              </p>

              <p className="seconddiv">
                <a className="button" href="https://backpacks.onrender.com/">
                  Go Live
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
