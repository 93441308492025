import React from "react";
import "./About.css";

const About = () => {
  return (
    <div id="about">
      <div className="section">
        <h2>.....About Me.....</h2>
        <div className="about">
          <p className="first">Hi Everyone 🖐️</p>
          <p className="second">
            I'm Tushar, a prefinal year Computer Science Engineering student at
            the National Institute of Technology, Rourkela. I specialize in Full
            Stack Development, primarily working with the MERN Stack. My
            programming arsenal includes languages such as C, C++, SQL, and
            JavaScript, among others.
          </p>
          <p className="second">
            Driven by an insatiable thirst for knowledge, I actively seek
            opportunities to learn, connect with others, and catalyze positive
            change. Beyond coding, you'll find me engaged in Hackathons,
            community programs, event hosting/attendance, and mentoring fellow
            students. I'm also passionate about outdoor sports like cricket and
            have a deep interest in chess.
          </p>
        </div>
        <h3>Education timeline</h3>
        <div className="timeline">
          <div className="container left">
            <div className="content">
              <h4>2021-NOW</h4>
              <h5>NIT, ROURKELA</h5>
              <p className="contents">
                Currently pursuing BTECH - CSE CGPA : 9.42
              </p>
            </div>
          </div>
          <div className="container right">
            <div className="content">
              <h4>2019-2021</h4>
              <h5>MBS PUBLIC SCHOOL</h5>

              <p className="contents">
                Completed my Higher Secondary Education with a score of 94.6%
              </p>
            </div>
          </div>
          <div className="container left">
            <div className="content">
              <h4>2006-2019</h4>
              <h5>Vikash Residential School</h5>

              <p className="contents">
                Completed my schooling with a score of 97.6%
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
