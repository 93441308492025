import React from "react";
import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import Skills from "./components/Skills";
import Services from "./components/Services";
import Contact from "./components/Contact";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <Home />
        <About />
        <Skills />
        <Services />
        <Contact />
      </BrowserRouter>
    </div>
  );
}

export default App;
