import React from "react";
import "./Skills.css";
import image2 from "./html5.png";
import image3 from "./css.jpg";
import image4 from "./JS.png";
import image5 from "./react.png";
import image6 from "./bootstrap.jpg";
import image7 from "./nodejs.png";
import image8 from "./C++.png";
import image9 from "./C.png";
import image10 from "./creative.png";
import image11 from "./leadership.png";
import image12 from "./time.jpg";
import image13 from "./adaptibility.png";
import image14 from "./teamwork.png";

const Skills = () => {
  return (
    <div className="skills">
      <div className="left2">
        <h2>Coding Skills</h2>
        <div className="skills-2">
          <div className="skills-3">
            <div className="firstdiv">
              <img src={image2} alt="" />
            </div>
            <p>HTML5</p>
          </div>
          <div className="skills-3">
            <div className="firstdiv">
              <img src={image3} alt="" />
            </div>
            <p>CSS3</p>
          </div>
          <div className="skills-3">
            <div className="firstdiv">
              <img src={image4} alt="" />
            </div>
            <p>JavaScipt</p>
          </div>
          <div className="skills-3">
            <div className="firstdiv">
              <img src={image5} alt="" />
            </div>
            <p>React JS</p>
          </div>
          <div className="skills-3">
            <div className="firstdiv">
              <img src={image6} alt="" />
            </div>
            <p>Bootstrap</p>
          </div>
          <div className="skills-3">
            <div className="firstdiv">
              <img src={image7} alt="" />
            </div>
            <p>Node JS</p>
          </div>
          <div className="skills-3">
            <div className="firstdiv">
              <img src={image8} alt="" />
            </div>
            <p>C++</p>
          </div>
          <div className="skills-3">
            <div className="firstdiv">
              <img src={image9} alt="" />
            </div>
            <p>C</p>
          </div>
        </div>
      </div>
      <div className="right2">
        <h2>Soft Skills</h2>
        <div className="skills-2">
          <div className="skills-3">
            <div className="firstdiv">
              <img src={image10} alt="" />
            </div>
            <p>creative thinking</p>
          </div>
          <div className="skills-3">
            <div className="firstdiv">
              <img src={image11} alt="" />
            </div>
            <p>Leadership</p>
          </div>
          <div className="skills-3">
            <div className="firstdiv">
              <img src={image12} alt="" />
            </div>
            <p>Time management</p>
          </div>
          <div className="skills-3">
            <div className="firstdiv">
              <img src={image13} alt="" />
            </div>
            <p>Adaptability</p>
          </div>
          <div className="skills-3">
            <div className="firstdiv">
              <img src={image14} alt="" />
            </div>
            <p>Teamwork</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
