import React, { useRef, useState } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";

const Contact = () => {
  function handleSubmit(e) {
    const formToReset = document.getElementById("contact-form");
  }

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_a736mwc",
        "template_16jlcjq",
        form.current,
        "jtYTJBppJc1YBrg3K"
      )
      .then(
        (result) => {
          alert("Thank you. I will get back to you as soon as possible.");
        },
        (error) => {
          alert("Ahh, something went wrong. Please try again.");
        }
      );
  };
  return (
    <div id="contact">
      <section>
        <h1 className="section-header">
          Contact ME
          <br />
          <span className="smallspan">
            Let's &nbsp;
            <span className="bigspan">
              <b>Talk</b>
            </span>
          </span>
        </h1>

        <div className="contact-wrapper">
          <form
            id="contact-form"
            className="form-horizontal"
            role="form"
            ref={form}
            onSubmit={sendEmail}
          >
            <div className="form-group">
              <div className="col-sm-12">
                <label htmlFor="name">What's Your Good Name?</label>
                <br></br>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="NAME"
                  name="name"
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="email">Your Email</label>
              <br></br>
              <div className="col-sm-12">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="EMAIL"
                  name="email"
                  required
                />
              </div>
            </div>
            <label htmlFor="textarea">What do you want to say?</label>
            <br></br>
            <textarea
              id="textarea"
              className="form-control"
              rows="10"
              placeholder="MESSAGE"
              name="message"
              required
            ></textarea>

            <button
              className="send-button btn btn-pink"
              id="submit"
              type="submit"
              value="Send"
              onClick={handleSubmit}
            >
              <i className="submit"></i>
              SUBMIT
            </button>
          </form>

          <div className="direct-contact-container">
            <ul className="contact-list">
              <li className="list-item">
                <i className="uil uil-location-pin-alt">
                  <span className="contact-text place">Titlagarh, Odisha</span>
                </i>
              </li>

              <li className="list-item">
                <i className="uil uil-outgoing-call">
                  <span className="contact-text phone">
                    <a href="tel:7735137705" title="Give me a call">
                      (+91) 7735137705
                    </a>
                  </span>
                </i>
              </li>

              <li className="list-item">
                <i className="uil uil-fast-mail">
                  <span className="contact-text gmail">
                    <a
                      href="mailto:sahutushar29@gmail.com"
                      title="Send me an email"
                    >
                      sahutushar29@gmail.com
                    </a>
                  </span>
                </i>
              </li>
            </ul>

            <hr />
            <ul className="social-media-list">
              <li>
                <a
                  href="https://www.instagram.com/tusharsahu3676/"
                  target="_blank"
                  className="contact-icon"
                >
                  <i className="uil uil-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/Anicrate"
                  target="_blank"
                  className="contact-icon"
                >
                  <i className="uil uil-github"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/sahutushar29"
                  target="_blank"
                  className="contact-icon"
                >
                  <i className="uil uil-twitter"></i>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/tushar-sahu-116a31241/">
                  <i className="uil uil-linkedin"></i>
                </a>
              </li>
            </ul>
            <hr />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
