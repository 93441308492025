import React, { useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { navLinks } from "./index.js";

const Header = () => {
  const [active, setActive] = useState("");

  return (
    <header className="header">
      <nav>
        <div>
          <img
            src="https://fontmeme.com/permalink/231223/0ff1393c8327ddd7e88d0fe6c72e2b12.png"
            alt="calligraphy-fonts"
            className="logo"
          />
          <span>...TS...</span>
        </div>
        <div>
          <ul>
            <li>
              <Link
                to="/home"
                onClick={() => {
                  setActive("");
                  window.scrollTo(0, 0);
                }}
              >
                <i className="uil uil-estate"></i>Home
              </Link>
            </li>
            {navLinks.map((nav) => (
              <li
                key={nav.id}
                onClick={() => {
                  setActive(nav.title);
                }}
              >
                <a href={`#${nav.id}`}>
                  <i className="uil uil-cloud-info"></i>
                  {nav.title}
                </a>
              </li>
            ))}
            {/* <li>
              <Link to="/skills">
                <i className="uil uil-award"></i>Skills
              </Link>
            </li>
            <li>
              <Link to="/services">
                <i className="uil uil-briefcase"></i>Work
              </Link>
            </li>
            <li>
              <Link to="/contact">
                <i className="uil uil-message"></i>Contact
              </Link>
            </li> */}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
