import React from "react";
import "./Home.css";
import ExampleComponent from "./ExampleComponent";
import profile from "./profile.jpeg";
import pdf from "./TusharSahu_121CS0230.pdf";
const Home = () => {
  return (
    <>
      <div className="total">
        <div className="entry">
          <h3>Hi 👋, I'M</h3>
          <h1>
            <b>TUSHAR SAHU </b>
          </h1>
          <ExampleComponent />
          <p>From INDIA</p>
          <div className="icons">
            <div className="wrapper">
              <button className="btn btn3 btn-pink">
                <i className="uil uil-arrow-up-right btn2 "></i>
                <a href={pdf} download>
                  RESUME
                </a>
              </button>
              <button className="btn btn3 btn-pink">
                <i className="uil uil-message btn2"></i>
                <a href={"#about"}>MORE ABOUT ME</a>
              </button>
            </div>
          </div>
          <div className="img1">
            <a href="https://www.instagram.com/tusharsahu3676/">
              <i className="uil uil-instagram u1"></i>
            </a>
            <a href="https://github.com/Anicrate">
              <i className="uil uil-github u1"></i>
            </a>
            <a href="https://www.linkedin.com/in/tushar-sahu-116a31241/">
              <i className="uil uil-linkedin u1"></i>
            </a>
            <a href="https://twitter.com/sahutushar29">
              <i className="uil uil-twitter u1"></i>
            </a>
          </div>
        </div>
        <div className="end">{<img src={profile} alt="Profile Image" />}</div>
      </div>
    </>
  );
};

export default Home;
