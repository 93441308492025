import React from "react";
import ReactTyped from "react-typed";
import "./ExampleComponent.css";

const ExampleComponent = () => {
  return (
    <div>
      <h1>
        I'm a{" "}
        <ReactTyped
          strings={["Web Developer", "Content Writer", "Student"]}
          typeSpeed={100}
          loop
          backSpeed={65}
          cursorChar=">"
          showCursor={false}
        />
      </h1>
    </div>
  );
};

export default ExampleComponent;
